<template>
    <div class="container-fluid">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="function-card">
      <button
        type="button"
        class="btn btn-danger"
        @click.prevent="getJobSetting"
      >
        重新整理
      </button> 
    </div>

    <div class="yahome-table-wrap">
      <VueBootstrap4Table :rows="rows" :columns="columns" :config="config">

        <template slot="LastExecuteTime" slot-scope="props">
          <div>
            <span>{{ props.row.LastExecuteTime | moment }}</span>
          </div>
        </template>

        <template slot="NextExecuteTime" slot-scope="props">
          <div>
            <span>{{ props.row.NextExecuteTime | moment }}</span>
          </div>
        </template>

        <template slot="execute" slot-scope="props">
          <button
            class="btn btn-success btn-edit btn-sm"
            style="margin-right: 0.5rem"
            @click="manualExecuteJob(props.row.JobType)"
          >
            立即執行
          </button>
        </template>

        <template slot="setting" slot-scope="props">
          <button
            class="btn btn-info btn-delete btn-sm"
            data-toggle="modal"
            data-target="#settingModal"
            @click="openModal(props.row)"
          >
            排程時間設定
          </button>
        </template>

        <template slot="log" slot-scope="props">
          <button
            class="btn btn-primary btn-delete btn-sm"
            data-toggle="modal"
            data-target="#recordModal"
            @click="getJobRecord(props.row.JobType)"
          >
            執行紀錄
          </button>
        </template>
        <template slot="empty-results"> 暫無資料 </template>

        <template slot="switch" slot-scope="props">
          <el-switch
            active-color="#13ce66"
            inactive-color="#808080"
            v-model="props.row.Enabled"
            @change="updateJobSettingEnabled(props.row)"
          >
          </el-switch>
        </template>
        <template slot="empty-results"> 暫無資料 </template>
      </VueBootstrap4Table>
    </div>


    <!-- 取得LOG Modal -->
    <div
      id="logModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="role"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span>取得LOG</span>
            </div>
            <div class="modal-body">
              <div class="field">
                <ValidationProvider
                  tag="div"
                  name="日期"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>選擇日期
                  </label>
                  <date-picker
                    class="w-100"
                    format="YYYY-MM-DD"
                    valueType="format"
                    placeholder="YYYY-MM-DD"
                  >
                  </date-picker>
                  <span v-if="errors[0] && !is_composing" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(saveRole)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>



    <!-- 排程時間 Modal -->

    <div
      id="settingModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="role"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span>排程時間設定</span>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-4">
                  <label>
                    <span class="mark">*</span>排程設定
                  </label>
                  <select class="form-control" v-model="setting.JobTimeType">
                    <option value="Month" >每月</option>
                    <option value="Week" >每週</option>
                    <option value="Day" >每日</option>
                  </select>

                  <label>
                    <input type="radio" name="timer" :value="false" v-model="setting.IsRepeatPeriod"> 執行時間
                  </label>
                  <date-picker v-model="setting.JobTimeSpan" type="time" format="HH:mm:ss" value-type="format" placeholder="請選擇"></date-picker>

                  <label>
                    <input type="radio" name="timer" :value="true" v-model="setting.IsRepeatPeriod"> 重複執行每隔
                  </label>
                  <select class="form-control" v-model="setting.RepeatPeriodMinute">
                    <option value="0" disabled>請選擇</option>
                    <option value="5" >5分鐘</option>
                    <option value="10" >10分鐘</option>
                    <option value="15" >15分鐘</option>
                    <option value="30" >30分鐘</option>
                    <option value="60" >60分鐘</option>
                  </select>

                </div>
                <div class="col-md-8">
                  <div class="month" v-if="setting.JobTimeType === 'Month'">
                    <!-- <span v-for="i in 30" :key="i">
                      <input type="checkbox">
                      <label for="">{{i}}</label>
                    </span> -->
                    <label v-for="i in 31" :key="i">
                        <input type="checkbox" :value="i" v-model="setting.JobDayOfMonth" />
                        <span>{{ i }}</span>
                    </label>
                  </div>

                  <div class="week" v-if="setting.JobTimeType === 'Week'">
                    <!-- <span v-for="i in 30" :key="i">
                      <input type="checkbox">
                      <label for="">{{i}}</label>
                    </span> -->
                    <label v-for="i in week" :key="i.value">
                        <input type="checkbox" :value="i.value" v-model="setting.JobDayOfWeek" />
                        <span>{{ i.label }}</span>
                    </label>
                  </div>
                </div>
                <!-- <div class="col-md-12">
                  <label>
                    <span class="mark">*</span>執行時間
                  </label>
                  <select class="form-control" v-model="setting.type">
                    <option value="1" >每月</option>
                    <option value="2" >每週</option>
                    <option value="3" >每日</option>
                  </select>

                  <label>
                    <span class="mark">*</span>重複執行每隔
                  </label>
                  <select class="form-control" v-model="setting.type">
                    <option value="1" >每月</option>
                    <option value="2" >每週</option>
                    <option value="3" >每日</option>
                  </select>
                </div> -->
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(updateTimeSetting)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>


    <!-- 執行紀錄 Modal -->
    <div
      id="recordModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="role"
          class="modal-content"
          v-slot="{ reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span>執行紀錄</span>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-7 col-sm-12">
                  <!-- <date-picker
                    v-model="recordDate"
                    format="YYYY-MM-DD"
                    type="date"
                    placeholder="請選擇"
                  ></date-picker> -->
                  <div class="form-group">
                    <label>查詢日期</label>
                    <date-picker
                      class="w-100"
                      v-model="recordDate"
                      value-type="format"
                      format="YYYY-MM-DD"
                      type="date"
                      placeholder="請選擇"
                      @change="getJobRecord(choiceJobType)"
                    ></date-picker>
                  </div>
                
                  <div class="yahome-table-wrap">
                    <VueBootstrap4Table :rows="recordList" :columns="recordColumns" :config="recordConfig">

                      <template slot="StartTime" slot-scope="props">
                        <div>
                          <span>{{ props.row.StartTime | moment }}</span>
                        </div>
                      </template>

                      <template slot="EndTime" slot-scope="props">
                        <div>
                          <span>{{ props.row.EndTime | moment }}</span>
                        </div>
                      </template>

                      <template slot="record" slot-scope="props">
                        <el-button
                            size="mini"
                            @click="choiceRecord(props.row)"
                          >紀錄</el-button>
                      </template>

                      <template slot="empty-results"> 暫無資料 </template>

                    </VueBootstrap4Table>
                  </div>

                </div>
                <div class="col-md-5 col-sm-12">
                  <span class="item-type">紀錄</span>
                  <div class="item-list">
                    <span v-if="record.Message">{{ record.Message }}</span>
                    <span v-else>尚未有資料</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

  </div>
</template>



<script>
/* global $ */
import moment from 'moment';
import { config } from "@/components/table_config.js";
import { mapGetters } from "vuex";

export default {
  name: "Schedule",

  components: {
    
  },
  data() {
    return {
        rows: [
          // {"Id":190,"Jobname":"信件寄送","StartTime":"2021/09/14 am 10:17","EndTime":"2021/09/14 am 10:17","Status":"閒置","Message":"","CreateUser":"User0111"},
          // {"Id":191,"Jobname":"抓取人事資料表","StartTime":"2021/09/17 am 10:17","EndTime":"2021/09/17 am 10:17","Status":"忙碌","Message":"","CreateUser":"User0111"}
        ],
        columns: [
            {
            label: "排程名稱",
            name: "JobName"
            },
            {
            label: "最近執行時間",
            name: "LastExecuteTime",
            slot_name: "LastExecuteTime",
            },
            {
            label: "下一次執行時間",
            name: "NextExecuteTime",
            slot_name: "NextExecuteTime",
            },
            {
            label: "操作",
            name: "execute",
            slot_name: "execute"
            },
            {
            label: "排程時間設定",
            name: "setting",
            slot_name: "setting"
            },
            {
            label: "執行紀錄",
            name: "log",
            slot_name: "log"
            },
            {
            label: "啟用",
            name: "switch",
            slot_name: "switch"
            },
        ],
        config,

        setting: {
          type: '1'
        },
        week: [
          {
            label: '星期一',
            value: '1'
          },
          {
            label: '星期二',
            value: '2'
          },
          {
            label: '星期三',
            value: '3'
          },
          {
            label: '星期四',
            value: '4'
          },
          {
            label: '星期五',
            value: '5'
          },
          {
            label: '星期六',
            value: '6'
          },
          {
            label: '星期日',
            value: '0'
          },
        ],
        recordDate: '',
        recordList: [],
        record: {},
        recordColumns: [
            {
              label: "開始時間",
              name: "StartTime",
              slot_name: "StartTime",
            },
            {
              label: "結束時間",
              name: "EndTime",
              slot_name: "EndTime",
            },
            {
              label: "狀態",
              name: "Status"
            },
            {
              label: "紀錄",
              name: "record",
              slot_name: "record"
            },
        ],
        recordConfig: {
          global_search: {
            visibility: false,
          },

          show_refresh_button: false,
          show_reset_button: false,

          pagination: true,
          pagination_info: false,
          num_of_visibile_pagination_buttons: 5,
          per_page: 5,
          per_page_options: [5],
          selected_rows_info: false,

          highlight_row_hover: false,
          rows_selectable: true,
          multi_column_sort: false,
          card_mode: false,
        },
        choiceJobType: '',
    };
  },

  computed: {
    ...mapGetters(["lang"]),
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  watch: {
    lang: function() {

    },
  },

  filters: {
    moment: function(date) {
      if(date === null) {
        return null
      } else {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
    },
  },

  methods: {
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    openModal(item) {
      this.setting = {
        ...item,
        JobDayOfWeek: item.JobDayOfWeek !== null ? item.JobDayOfWeek.split(",") : [],
        JobDayOfMonth: item.JobDayOfMonth !== null ? item.JobDayOfMonth.split(",") : []
      }
    },
    getJobSetting() { //  取得所有排程
      const vm = this;
      const api = `${window.BaseUrl.api}/JobViewer/getJobSetting`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            // vm.rows = response.data
            vm.rows = response.data.map(item => {
              return {
                ...item,
                Status: item.Status === 'Running' ? '忙碌' : '閒置'
              }
            })

            vm.$store.dispatch("updateLoading", false);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            vm.$notify({
              title: "錯誤",
              message: "無法正確抓到資料",
              type: "error",
              duration: 3500,
            });
          } else {
            vm.$notify({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 3500,
            });
          }

          vm.$store.dispatch("updateLoading", false);
        });
    },
    manualExecuteJob(JobType) { //  手動執行排程
      const vm = this;
      const api = `${window.BaseUrl.api}/JobViewer/manualExecuteJob`;

      const data = {
        createUser: vm.$cookies.get("userId"),
        JobType: JobType
      }

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {

            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "成功執行排程",
              type: "success",
              duration: 2500,
            });

            vm.getJobSetting()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            vm.$notify({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 3500,
            });
          }

          vm.$store.dispatch("updateLoading", false);
        });
    },
    updateJobSettingEnabled(item) { //  排程啟用開關
      const vm = this;
      const api = `${window.BaseUrl.api}/JobViewer/updateJobSettingEnabled`;

      const data = {
        Id : item.Id,
        UpdateUser: vm.$cookies.get("userId"),
        Enabled: item.Enabled
      }

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {

            vm.$store.dispatch("updateLoading", false);
            vm.getJobSetting()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            vm.$notify({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 3500,
            });
          }

          vm.$store.dispatch("updateLoading", false);
        });
    },
    updateTimeSetting() { //  排程時間設定
      const vm = this;
      const api = `${window.BaseUrl.api}/JobViewer/updateJobSettingTimeSetting`;

      const data = {
        // ...vm.setting,
        Id: vm.setting.Id,
        UpdateUser: vm.$cookies.get("userId"),
        JobTimeType: vm.setting.JobTimeType,
        JobDayOfWeek: vm.setting.JobTimeType === 'Week' ? vm.setting.JobDayOfWeek : null,
        JobDayOfMonth:  vm.setting.JobTimeType === 'Month' ? vm.setting.JobDayOfMonth : null,
        IsRepeatPeriod: vm.setting.IsRepeatPeriod,
        JobTimeSpan: vm.setting.JobTimeSpan,
        RepeatPeriodMinute: vm.setting.RepeatPeriodMinute,
      }


      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {

            $('#settingModal').modal('hide')
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "成功設定排程時間",
              type: "success",
              duration: 2500,
            });

            vm.getJobSetting()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            vm.$notify({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 3500,
            });
          }

          $('#settingModal').modal('hide')
          vm.$store.dispatch("updateLoading", false);
        });
    },
    getJobRecord(JobType) { //  取得 所有該排程紀錄
      const vm = this;
      const api = `${window.BaseUrl.api}/JobViewer/getJobRecord`;

      if(vm.choiceJobType !== JobType) {
        vm.recordDate = moment().format('YYYY-MM-DD');
      }

      vm.choiceJobType = JobType
      const params = {
        jobType: vm.choiceJobType,
        date: vm.recordDate,
      }

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api, {params})
        .then((response) => {
          if (response.status === 200) {
            vm.recordList = response.data
            vm.record = {}
            vm.$store.dispatch("updateLoading", false);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            vm.$notify({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 3500,
            });
          }
          vm.$store.dispatch("updateLoading", false);
        });
    },
    choiceRecord(record) {
      this.record = record
    }
  },

  created() {
    this.getJobSetting()
    this.recordDate = moment().format('YYYY-MM-DD');
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  },
};
</script>


<style lang="scss" scoped>
.modal-dialog {
  min-width: 800px;
}

.month {
  // height: 100%;
  display: flex;
  flex-wrap: wrap;

  span {
    width: 40px;
    border-radius:  6px;
    margin-bottom: 1rem ;
    text-align: center;
  }

  label {
    margin: 0 4px;
  }
}

.week {
  // height: 100%;
  display: flex;
  flex-wrap: wrap;

  span {
    // width: 40px;
    width: 110px;
    border-radius:  6px;
    margin-bottom: 1rem ;
    text-align: center;
  }

  label {
    margin: 0 4px;
  }
}

.col-md-4 {
  border-right: 1px solid #f1f1f1;
}

.form-control {
  margin-bottom: 1rem;
}

.mx-datepicker {
  width: 100%;
  height: 38px;
  margin-bottom: 1rem;
}

label {
    padding: 0;
    margin-right: 3px;
    cursor: pointer;
}
input[type=checkbox] {
    display: none;
}
input[type=checkbox]+span {
    display: inline-block;
    // background-color: #aaa;
    background-color: #d3d3d3;
    padding: 3px 6px;
    border: 1px solid gray;
    color: #333;
    user-select: none; /* 防止文字被滑鼠選取反白 */
}

input[type=checkbox]:checked+span {
    // color: yellow;
    // background-color: #444;
    color: #fff;
    background-color: #5380f7;
}

#recordModal .modal-dialog {
  min-width: 900px;
}

.item-type {
  background-color: white;
  color: #33344a;
  padding: 3px 10px;
  position: absolute;
  top: -22px;
  left: 30px;
}

.item-list {
  // height: 400px;
  height: 100%;
  overflow: auto;
  border: 2px solid #33344a;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

.form-group {
  label {
    font-size: 0.8em;
  }
}

.yahome-table-wrap {
  padding: 0;
}

</style>


